"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import throttle from "lodash/throttle";
import { scrollConfig } from "./scroll-config";
import { SubscribeService } from "./subscribe-service";
class ScrollService extends SubscribeService {
  constructor() {
    super();
    __publicField(this, "scrollToCenter", (element, options = {}) => {
      const { pageYOffset } = window;
      const { clientHeight } = document.documentElement;
      const { top } = element.getBoundingClientRect();
      const pos = pageYOffset + top - clientHeight / 2;
      this.scroll(pos, options);
    });
    __publicField(this, "scrollToElementsTop", (element, container = null, offset = 0, options = {}) => {
      const scrollY = container ? container.scrollTop : window.scrollY;
      const top = container ? element.getBoundingClientRect().top - container.getBoundingClientRect().top : element.getBoundingClientRect().top;
      const pos = top + scrollY + offset;
      const duration = Math.max(Math.min(Math.abs(document.documentElement.scrollTop - pos), scrollConfig.maxDuration), scrollConfig.minDuration);
      this.scroll(pos, __spreadValues({ duration }, options), container);
    });
    __publicField(this, "scroll", (pos = 0, options = {}, container = null) => {
      this.animateScroll(pos, options, container);
    });
    const handler = throttle(this.emit, scrollConfig.throttleTime);
    window.addEventListener("scroll", () => handler(), { passive: true });
  }
  animateScroll(destination, options = {}, container) {
    const defaultOptions = {
      duration: scrollConfig.minDuration,
      easingFunction: scrollConfig.easings.easeInOutQuart
    };
    const { duration, easingFunction } = __spreadValues(__spreadValues({}, defaultOptions), options);
    const start = container ? container.scrollTop : window.scrollY;
    const startTime = "now" in window.performance ? performance.now() : new Date().getTime();
    const containerHeight = container ? Math.max(container.clientHeight, container.scrollHeight, container.offsetHeight) : Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    const containerViewHeight = container ? container.getBoundingClientRect().height : window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName("body")[0].clientHeight;
    const destinationOffset = destination;
    const destinationOffsetToScroll = Math.round(containerHeight - destinationOffset < containerViewHeight ? containerHeight - containerViewHeight : destinationOffset);
    const element = container || window;
    if (!("requestAnimationFrame" in window)) {
      element.scroll(0, destinationOffsetToScroll);
      return;
    }
    function scroll() {
      const now = "now" in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      const timeFunctionResult = easingFunction(time);
      const scrollY = "scrollTop" in element ? element.scrollTop : window.scrollY;
      element.scroll(0, Math.ceil(timeFunctionResult * (destinationOffsetToScroll - start) + start));
      if (Math.ceil(scrollY) === destinationOffsetToScroll) {
        return;
      }
      requestAnimationFrame(scroll);
    }
    scroll();
  }
}
export const scrollService = new ScrollService();
