"use strict";
export const scrollConfig = Object.freeze({
  maxDuration: 600,
  minDuration: 300,
  throttleTime: 100,
  easings: {
    linear: (t) => t,
    easeInOutQuart: (t) => t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
  }
});
