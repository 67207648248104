"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class SubscribeService {
  constructor() {
    __publicField(this, "_subscribers", {});
    __publicField(this, "subscribe", (id, cb) => {
      this._subscribers[id] = cb;
    });
    __publicField(this, "unsubscribe", (id) => {
      if (id && this._subscribers[id])
        delete this._subscribers[id];
    });
    __publicField(this, "emit", (id, serviceArgument) => {
      Object.keys(this._subscribers).forEach((key) => {
        if (key !== id)
          this._subscribers[key](serviceArgument);
      });
    });
  }
}
